<template>
  <div class="flex flex-col sela-container min-h-screen py-20">
    <div class="flex-auto relative">
      <div class="auth-parallelograms -bottom-8"></div>
    </div>
    <div class="auth-container">
      <div class="flex items-center">
        <div class="flex-1 self-stretch pr-8 pl-8 sm:ltr:pr-0 sm:rtl:pl-0">
          <!-- Route Toggle -->
          <div class="flex mb-5">
            <SelaLink
              :with-branch-id-query="false"
              to="/auth/login"
              :aria-label="t('login')"
              class="auth-link"
            >
              {{ t('login') }}
            </SelaLink>
            <div class="w-12"></div>
            <SelaLink
              :with-branch-id-query="false"
              to="/auth/register"
              :aria-label="t('register')"
              class="auth-link"
            >
              {{ t('register') }}
            </SelaLink>
          </div>

          <!-- Auth View -->
          <NuxtPage />
        </div>
        <!-- Auth Logo -->
        <div class="flex-1 hidden sm:block">
          <img
            width="256"
            height="241"
            src="/images/sela.webp"
            class="w-72 mx-auto object-contain"
            :alt="t('sila')"
          />
        </div>
      </div>
    </div>
    <div class="flex-auto relative">
      <div class="auth-parallelograms -top-8"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
</script>

<style scoped>
.auth-container {
  @apply relative z-10 bg-bg_alt_color py-5 w-full sm:w-10/12 lg:w-8/12 mx-auto rounded shadow-lg;
}
.auth-parallelograms {
  @apply absolute ltr:left-1/4 rtl:right-1/4 -skew-y-12 bg-nav_color w-36 h-20 opacity-10;
}
.auth-link {
  @apply font-bold text-text_color text-opacity-40 border-b-4 border-transparent hover:text-nav_color hover:border-nav_color transition-all duration-500;
}
.auth-link.router-link-exact-active {
  @apply text-nav_color border-nav_color !important;
}
</style>

<style>
.sela-form-title {
  @apply font-semibold text-lg text-text_color mt-5;
}
</style>
